export default {
    data(){
        return { 
            lang:{
                en:{
                    finished:{
                        header:"Thanks For Your Evaluation",
                        btn:"Start Again"
                    }
                },
                ar:{
                    finished:{
                        header:"شكرا علي تقيمك لنا",
                        btn:"البدء مرة أخري"
                    }
                }
            }
        }
    },
}