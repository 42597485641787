export default {
    data(){
        return{
            lang:{
                en:{
                    poor:"Poor",
                    good:"Good"
                },
                ar:{
                    poor:"سئ",
                    good:"جيد"
                }
            }
        }
    }
}