export default {
    data(){
        return{
            lang:{
                en:{
                    options:{
                        skip:"Skip All",
                        logOut:"Log Out",
                        language:{
                            name:'العربية',
                            code:'ar'
                        },
                    },
                    dialog:{
                        header:'Are You Sure ?',
                        confirm:'Confirm',
                        cancel:'Cancel'
                    },
                    form:{
                        password:{
                            label:'Password',
                            placeholder:'Write The Confirmation Password',
                        }
                    }
                },
                ar:{
                    options:{
                        skip:"نخطي الكل",
                        logOut:"تسجيل الخروج",
                        language:{
                            name:"English",
                            code:'en'
                        },
                    },
                    dialog:{
                        header:'هل انت متأكد',
                        confirm:'تأكيد',
                        cancel:'الغاء'
                    },
                    form:{
                        password:{
                            label:'كلمة المرور',
                            placeholder:'اكتب كلمة المرور التأكيدية',
                        }
                    }
                }
            }
        }
    },
}