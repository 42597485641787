var local = localStorage.getItem("lang")?localStorage.getItem("lang"):'en';
//used
function requiredErorr(fieldName){
    if (local == 'en')
        return `${fieldName} is required`
    return `${fieldName} مطلوب  `
}
function inputError(label){
    if (local == 'en')
        return `${label} is not valid`
    return ` ${label} غير صحيح`
}
function minLengthError(fieldName,min){
    if (local == 'en')
        return `${fieldName} must be more than ${min} charcters`
    return `${fieldName} لا يمكن ان يكون اقل من  ${min} احرف`
}
function maxLengthError(fieldName,min){
    if (local == 'en')
        return `${fieldName} must be less than ${min} charcters`
    return `${fieldName} لا يمكن ان يكون اكثر من  ${min} احرف`
}
function numericError(fieldName){
    if (local == 'en')
        return `${fieldName} must only be numbers`
    return `${fieldName} يجب ان يكون ارقام فقط `

}





function notExistError(label){
    if (local == 'en')
        return `${label} already exists`
    return `${label}  موجود بالفعل`
}
function minValueError(fieldName,min){
    if (local == 'en')
        return `${fieldName} must be more than ${min}`
    return `${fieldName} لا يمكن ان يكون اقل من  ${min}`
}
function maxValueError(fieldName,min){
    if (local == 'en')
        return `${fieldName} must be less than ${min}`
    return `${fieldName} لا يمكن ان يكون اكثر من  ${min}`
}
function alphaError(fieldName){
    if (local == 'en')
        return `${fieldName} must only be characters`
    return `${fieldName} يجب ان يكون احرف فقط `

}
function alphaNumericError(fieldName){
    if (local == 'en')
        return `${fieldName} must only be characters or numbers`
    return `${fieldName} يجب ان يكون احرف او ارقام فقط `

}
function sameAsError(fieldName){
    if (local == 'en')
        return `${fieldName} isn't matched`
    return `${fieldName} ليس متطابق `

}
function htmlTagsError(fieldName){
    if (local == 'en')
        return `${fieldName} can't contain HTML tags`
    return `${fieldName}  لا يجب ان يحتوي علي HTML tags `

}
function imageTypeError(){
    if (local == 'en')
        return `Image type not valid ,allowed extensions(.png,.jpg,.jpeg)`
    return `امتداد الصورة غير صحيح(.png,.jpg,.jpeg)`

}
function imageSizeError(size){
    if (local == 'en')
        return `Image size must be less than ${size}MB`
    return ` لايجب ان يزيد حجم الصورة عن ${size}MB`

}
function unauthorizedError(){
    if (local == 'en')
        return `Phone or Password Not valid`
    return ` رقم الهاتف او كلمه المرور غير صحيح`
}
function notFoundError(label){
    if (local == 'en')
        return `${label} Not Exist`
    return `${label} غير موجود`
   
}




module.exports = {
    requiredErorr,
    inputError,
    notExistError,
    minLengthError,
    maxLengthError,
    minValueError,
    maxValueError,
    alphaError,
    numericError,
    alphaNumericError,
    sameAsError,
    htmlTagsError,
    imageTypeError,
    imageSizeError,
    unauthorizedError,
    notFoundError,

}