export default {
    data(){
        return{
                lang:{
                    en:{
                        label:{
                            bad:"Bad",
                            good:"Good",
                            veryGood:"Very Good",
                            excellent:"Excellent"
                        }

                    },
                    ar:{
                        label:{
                            bad:"سئ",
                            good:"جيد",
                            veryGood:"جيد جدا",
                            excellent:"ممتاز"
                        }

                    },
                }
        }
    }
}