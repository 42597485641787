<template>
    <div class="rate-types-wrapper w-100 h-100" :class="{'arabic':local=='ar'}">
        <div class="settings p-3 d-flex justify-content-between">
            <div class="settings-wrapper">
                <Button type="button" icon="pi pi-cog" class="p-button-rounded" @click="toggle" />
                <OverlayPanel ref="op">
                    <div v-if="!fullScreenMode" class="option log-out p-2 d-flex" @click.prevent="setOnFullScreen">
                        <span><i class="pi pi-window-maximize"></i></span>
                        <span class="mx-2">Full Screen</span>
                    </div>
                    <div v-if="fullScreenMode" class="option log-out p-2 d-flex" @click.prevent="closeFullscreen">
                        <span><i class="pi pi-window-minimize"></i></span>
                        <span class="mx-2">Regular Screen</span>
                    </div>
                    <hr>
                    <div class="option log-out p-2 d-flex" @click.prevent="setLanguage(lang[local].options.language.code)">
                        <span><i class="pi pi-globe"></i></span>
                        <span class="mx-2">{{lang[local].options.language.name}}</span>
                    </div>
                    <hr>
                    <div class="option skip p-2 d-flex" @click.prevent="openDialog('skip')">
                        <span><i class="pi pi-refresh"></i></span>
                        <span class="mx-2">{{lang[local].options.skip}}</span>
                    </div>
                    <hr>
                    <div class="option log-out p-2 d-flex" @click.prevent="openDialog('logout')">
                        <span><i class="pi pi-sign-out"></i></span>
                        <span class="mx-2">{{lang[local].options.logOut}}</span>
                    </div>
                </OverlayPanel>
            </div>
        </div>
        <div class="types-wrapper p-3 d-flex justify-content-center align-items-center">
            <EmojisType v-if="type==1" ref="TypeComponent"></EmojisType>
            <ButtonsType v-if="type==3" ref="TypeComponent"></ButtonsType>
            <StarsType v-if="type==2" ref="TypeComponent"></StarsType>
        </div>
        <div class="skip-and-logout">
            <Dialog 
                    :header="lang[local].dialog.header" 
                    :visible.sync="showDialog" 
                    :closeOnEscape="false" :closable="false" 
                    :style="{width:'50vh'}" :modal="true"
            >
                <div class="confirmation-content d-flex align-items-center">
                    <div class="password flex-grow-1">
                        <div class="label">
                            <label>{{lang[local].form.password.label}}</label>
                        </div>
                        <div class="input-wrapper">
                            <Password
                                :feedback="false"
                                :toggleMask="true"
                                :class="{'p-invalid':$v.form.password.$error}"
                                :placeholder="lang[local].form.password.placeholder" 
                                v-model="form.password"
                                @input="$v.form.password.$touch()"
                            />
                        </div>
                        <div class="errors">
                            <small 
                                v-if="!$v.form.password.required && $v.form.password.$error" class="text-danger">
                                {{errors.requiredErorr(lang[local].form.password.label)}}
                            </small>
                            <small 
                                v-else-if="!staticPassword.valid" class="text-danger">
                                {{errors.inputError(lang[local].form.password.label)}}
                            </small>
                        </div>
                    </div>
                </div>
                <template #footer>
                    <Button :label="lang[local].dialog.confirm" :disabled="$v.form.$invalid" icon="pi pi-check" @click="confirm" class="p-button-raised" />
                    <Button :label="lang[local].dialog.cancel" icon="pi pi-times" @click="closeDialog" class="p-button-raised p-button-danger" />
                </template>
            </Dialog>
        </div>
    </div>
</template>
<script>
import {requiredErorr,inputError} from '../helpers/errors.js';
import {required} from 'vuelidate/lib/validators';
import skipAndLogoutLang from '../mixins/translations/skipAndLogoutLang.js'
import Button from 'primevue/button';
import OverlayPanel from 'primevue/overlaypanel';
import Dialog from 'primevue/dialog';
import Password from 'primevue/password';
import EmojisType from '../components/EmojisType.vue';
import ButtonsType from '../components/ButtonsType.vue';
import StarsType from '../components/StarsType.vue';
export default {
    mixins:[skipAndLogoutLang],
    components:{
        Button,
        OverlayPanel,
        Dialog,
        Password,
        EmojisType,
        ButtonsType,
        StarsType
    },
    data(){
        return{
            local:localStorage.getItem('lang')?localStorage.getItem('lang'):'en',
            type:JSON.parse(localStorage.getItem('user'))['branch']['type']?JSON.parse(localStorage.getItem('user'))['branch']['type']:1,
            option:'',
            showDialog:false,
            staticPassword:{
                key:'123456789',
                valid:true,
            },
            form:{
                password:'',
            },
            errors:{
                requiredErorr,
                inputError
            },
            fullScreenMode:false,
        }
    },
    validations:{
        form:{
            password:{
                required
            }
        }
    },
    beforeRouteEnter(to, from, next) {
            if(!localStorage.getItem('user')){
                next({name:"login"})
                return;
            }
            let user=JSON.parse(localStorage.getItem('user')); 
            if(!user.token || !user.branch.client_id || !user.branch.type || !user.branch.id){
                localStorage.removeItem('user');
                next({name:"login"});
                return;
            }
            next();
    },
    methods:{
        setOnFullScreen(){
            this.fullScreenMode=true;
            /* Get the documentElement (<html>) to display the page in fullscreen */
            var elem = document.documentElement;
            /* View in fullscreen */
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.webkitRequestFullscreen) { /* Safari */
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) { /* IE11 */
                elem.msRequestFullscreen();
            }
        },
        closeFullscreen() {
            this.fullScreenMode=false;
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) { /* Safari */
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { /* IE11 */
                document.msExitFullscreen();
            }
        },
        setLanguage(lang){
            // localStorage.removeItem('questions');
            localStorage.removeItem('questions');
            localStorage.setItem("lang",lang);
            location.reload();
        },
        toggle(event) {
            this.$refs.op.toggle(event);
        },
        openDialog(option){
            this.option=option;
            this.showDialog=true;
        },
        closeDialog(){
            this.option='';
            this.form.password='';
            this.$v.$reset();
            this.showDialog=false;
        },
        skip(){
            if(!this.$v.form.$invalid)
                if(this.form.password===this.staticPassword.key){
                    this.staticPassword.valid=true
                    this.$refs.TypeComponent.skipQuestions();
                    this.closeDialog();
                }
                else
                    this.staticPassword.valid=false
        },
        logOut(){
            if(!this.$v.form.$invalid)
                if(this.form.password===this.staticPassword.key){
                    this.staticPassword.valid=true
                    let lang=localStorage.getItem('lang')?localStorage.getItem('lang'):'en';
                    localStorage.clear();
                    localStorage.setItem('lang',lang)
                    this.closeDialog();
                    this.$router.push({name: "login"})
                }
                else
                    this.staticPassword.valid=false
            else
                console.log('Not Today')
        },
        confirm(){
            if(this.option === 'skip')
                this.skip();
            else if(this.option === 'logout')
                this.logOut();
        },
    }
}
</script>
<style lang="scss" scoped>
.rate-types-wrapper{
    .settings{
        // background-color: #e8f5e9;
        .settings-wrapper{
            max-width:200px;
            .option{
                border-radius: 3px;
                .pi{
                    font-weight: bold;
                    color: #4CAF50;
                }
                &:hover{
                    .pi{
                        color: white !important;
                    }
                    color: white;
                    background-color: #4CAF50;
                    cursor: pointer;
                }
            }
            ::v-deep .p-overlaypanel{
                max-width:200px !important;
            }
        }
    }
    .types-wrapper{
        min-height: calc(100vh - 90px);
    }
}
</style>