export default{
    data(){
        return{
            lang:{
                en:{
                    header:{
                        label:"Descripe Your Complaint!"
                    },
                    form:{
                        name:{
                            label:"Name"
                        },
                        phone:{
                            label:"Phone"
                        },
                        comment:{
                            label:"Comment"
                        },
                    },
                    footer:{
                        confirm:"confirm",
                        cancel:"Cancel"
                    },
                },
                ar:{
                    header:{
                        label:"!أوصف شكوتك"
                    },
                    form:{
                        name:{
                            label:"الاسم"
                        },
                        phone:{
                            label:"رقم الهاتف"
                        },
                        comment:{
                            label:"التعليق"
                        },
                    },
                    footer:{
                        confirm:"تأكيد",
                        cancel:"الغاء"
                    },
                }
            }
        }
    },
}