import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import Rate from "../views/Rate.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/dist",
    name: "login",
    component: Login,
  },
  {
    path: "/dist/rate",
    name: "rate",
    component: Rate,
  },
  
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
