<template>
    <div v-if="!loading &&questions.length &&emojis" class="rate ">
        <div v-if="questions.length!=current" class="question question p-sm-5 px-2 py-5">
            <div class="mb-5">
                <h2 class="text-center">{{questions[current].question}}</h2>
            </div>
            <div class="emojis-wrapper d-flex justify-content-center align-items-center">
                <div class="emoji" @click.prevent="addRate(rate=1)">
                    <img :src="`${backendMediaUrl}${emojis.image1_path}`" :alt="emojis.image1_path">
                </div>
                <div class="emoji" @click.prevent="addRate(rate=2)">
                    <img :src="`${backendMediaUrl}${emojis.image2_path}`" :alt="emojis.image2_path">
                </div>
                <div class="emoji" @click.prevent="addRate(rate=3)">
                    <img :src="`${backendMediaUrl}${emojis.image3_path}`" :alt="emojis.image3_path">
                </div>
                <div class="emoji" @click.prevent="addRate(rate=4)">
                    <img :src="`${backendMediaUrl}${emojis.image4_path}`" :alt="emojis.image4_path">
                </div>
            </div>
            
            <div class="sending mt-4 d-flex justify-content-center align-items-center" v-if="sending">
                <ProgressSpinner style="width:100px;height:100px"/>
            </div>
        </div>
        <div v-else-if="questions.length==current" class="question question p-sm-5 p-2">
            <h2 class="text-center">{{lang[local].finished.header}}</h2>
            <hr>
            <div class="try-again">         
                <Button                                         
                    :label="lang[local].finished.btn"
                    @click.prevent="skipQuestions"   
                />
            </div>
        </div>
        <div class="unAuthorized">
            <Dialog 
                    :header="lang[local].dialog.header" 
                    :visible.sync="showErrorDialog" 
                    :closeOnEscape="false" :closable="false" 
                    :style="{width:'50vh'}" :modal="true"
            >
                <div class="confirmation-content d-flex  align-items-center">
                    <i class="pi pi-exclamation-triangle text-danger" style="font-size: 2rem" />
                    <span class="p-2">{{lang[local].dialog.msgError}}</span>
                </div>
                <template #footer>
                    <Button :label="lang[local].dialog.btn" icon="pi pi-check" @click="closeWarning" class="p-button-raised p-button-danger" />
                </template>
            </Dialog>
        </div>
        <div class="bad-rate">
            <Dialog 
                    :header="lang[local].header.label" 
                    :visible.sync="showBadRateDialog" 
                    :closeOnEscape="false" :closable="false" 
                    :style="{width:'50vh'}" :modal="true"
            >
                <div class="confirmation-content d-flex align-items-center" :class="{arabic:local=='ar'}">
                    <div class="form-wrapper flex-grow-1 p-2">
                        <div class="name my-2">
                            <div class="label">
                                <label>{{lang[local].form.name.label}}</label>
                            </div>
                            <div class="input-wrapper">
                                <InputText
                                    :class="{'p-invalid':$v.form.name.$error}" 
                                    v-model="form.name"
                                    @input="$v.form.name.$touch()"
                                />
                            </div>
                            <div class="errors">
                                <small v-if="!$v.form.name.required && $v.form.name.$error" class="text-danger">
                                    {{errors.requiredErorr(lang[local].form.name.label)}}
                                </small>
                                <small v-else-if="!$v.form.name.minlen && $v.form.name.$error" class="text-danger">
                                    {{errors.minLengthError(lang[local].form.name.label,$v.form.name.$params.minlen.min)}}
                                </small>
                                <small v-else-if="!$v.form.name.maxlen && $v.form.name.$error" class="text-danger">
                                    {{errors.maxLengthError(lang[local].form.name.label,$v.form.name.$params.maxlen.max)}}
                                </small>
                            </div>
                        </div>
                        <div class="phone my-2">
                            <div class="label">
                                <label>{{lang[local].form.phone.label}}</label>
                            </div>
                            <div class="input-wrapper">
                                <InputText 
                                    type="tel" 
                                    :class="{'p-invalid':$v.form.phone.$error}"  
                                    v-model="form.phone"
                                    @input="$v.form.phone.$touch()"
                                />
                            </div>
                            <div class="errors">
                                <small v-if="!$v.form.phone.required && $v.form.phone.$error" class="text-danger">
                                    {{errors.requiredErorr(lang[local].form.phone.label)}}
                                </small>
                                <small v-else-if="!$v.form.phone.minlen && $v.form.phone.$error" class="text-danger">
                                    {{errors.minLengthError(lang[local].form.phone.label,$v.form.phone.$params.minlen.min)}}
                                </small>
                                <small v-else-if="!$v.form.phone.maxlen && $v.form.phone.$error" class="text-danger">
                                    {{errors.maxLengthError(lang[local].form.phone.label,$v.form.phone.$params.maxlen.max)}}
                                </small>
                            </div>
                        </div>
                        <div class="comment my-2">
                            <div class="label">
                                <label>{{lang[local].form.comment.label}}</label>
                            </div>
                            <div class="input-wrapper">
                                <Textarea
                                    rows="5"
                                    :class="{'p-invalid':$v.form.comment.$error}" 
                                    v-model="form.comment"
                                    @input="$v.form.comment.$touch()"
                                />
                            </div>
                            <div class="errors">
                                <small v-if="!$v.form.comment.required && $v.form.comment.$error" class="text-danger">
                                    {{errors.requiredErorr(lang[local].form.comment.label)}}
                                </small>
                                <small v-else-if="!$v.form.comment.minlen && $v.form.comment.$error" class="text-danger">
                                    {{errors.minLengthError(lang[local].form.comment.label,$v.form.comment.$params.minlen.min)}}
                                </small>
                                <small v-else-if="!$v.form.comment.maxlen && $v.form.comment.$error" class="text-danger">
                                    {{errors.maxLengthError(lang[local].form.comment.label,$v.form.comment.$params.maxlen.max)}}
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
                <template #footer >
                    <div></div>
                    <Button :label="lang[local].footer.confirm" :disabled="$v.form.$invalid" @click.prevent="confirmBadRateDialog"   />
                    <Button :label="lang[local].footer.cancel" icon="pi pi-times" @click="closeBadRateDialog" class="p-button-raised p-button-danger" />
                </template>
            </Dialog>
        </div>
    </div>
    <div v-else>
        <div class="loading mt-4 d-flex justify-content-center align-items-center">
            <ProgressSpinner style="width:150px;height:150px"/>
        </div>
    </div>
</template>
<script>
import backendDomains from '../helpers/backendDomains.js';
import finishedLang from '../mixins/translations/finishedLang.js';
import unAuthorizedLang from '../mixins/translations/unAuthorizedLang.js';
import badRateLang from '../mixins/translations/badRateLang.js';
import {requiredErorr,minLengthError,maxLengthError} from '../helpers/errors.js';
import {required,minLength,maxLength} from 'vuelidate/lib/validators';
import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea';
import ProgressSpinner from 'primevue/progressspinner';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import axios from 'axios'
export default {
    mixins:[backendDomains,finishedLang,unAuthorizedLang,badRateLang],
    components:{
        InputText,
        Textarea,
        ProgressSpinner,
        Button,
        Dialog
    },
    data(){
        return{
            loading:false,
            local:localStorage.getItem('lang')?localStorage.getItem('lang'):'en',
            current:0,
            badRate:0,
            questions:JSON.parse(localStorage.getItem('questions'))?JSON.parse(localStorage.getItem('questions')):[],
            emojis:JSON.parse(localStorage.getItem('emojis'))?JSON.parse(localStorage.getItem('emojis')):{},
            clientId:parseInt(JSON.parse(localStorage.getItem('user'))['branch']['client_id']),
            branchId:parseInt(JSON.parse(localStorage.getItem('user'))['branch']['id']),
            sending:false,
            showErrorDialog:false,
            showBadRateDialog:false,
            form:{
                phone:'',
                name:'',
                comment:''
            },
            errors:{
                requiredErorr,
                minLengthError,
                maxLengthError,
            },
        }
    },
    validations:{
        form:{
            name:{
                required,
                minlen: minLength(3),
                maxlen: maxLength(255),
            },
            phone:{
                required,
                minlen: minLength(11),
                maxlen: maxLength(15),
            },
            comment:{
                required,
                minlen: minLength(3),
                maxlen: maxLength(255),
            },
        }
    },
    created(){
        if(localStorage.getItem('questions')==null||localStorage.getItem('emojis')==null){
            this.loading=true;
            Promise.all([
                this.getEmojis(),
                this.getQuestions(),
            ])
            .then(() => {
                this.loading=false
            })
        }

    },
    methods:{
        closeWarning(){
            let lang = localStorage.getItem('lang')?localStorage.getItem('lang'):'ar'
            localStorage.clear();
            localStorage.setItem('lang',lang)
            this.showErrorDialog=false;
            this.$router.push({name: "login"})
        },
        getQuestions(){
            axios
            .post(`${this.backendBaseUrl}/machine/getQuestions`,{client_id:this.clientId},
                {
                    'headers':{
                        'X-localization':localStorage.getItem('lang')?localStorage.getItem('lang'):'en',
                    }
                }
            )
            .then(res=> {
                // console.log(res);
                if(res.data.status){
                    let questions = JSON.stringify(res.data.questions)
                    localStorage.setItem('questions',questions)
                    this.questions=[...res.data.questions]
                }
            })
            .catch(err=> {
                // console.log(err);
                if(err.response.status>400){
                    this.showDialogError=true;
                }
            });
        },
        getEmojis(){
            axios
            .post(`${this.backendBaseUrl}/machine/getEmoji`,{client_id:this.clientId})
            .then(res=> {
                // console.log(res);
                if(res.data.status){
                    let emojis = JSON.stringify(res.data.emojis)
                    localStorage.setItem('emojis',emojis)
                    this.emojis={...res.data.emojis}
                }
            })
            .catch(err=> {
                // console.log(err);
                if(err.response.status>400){
                    this.showDialogError=true;
                }
            });
        },
        openBadRateDialog(rate){
            if(localStorage.getItem('evaluator')!=null){
                if(JSON.parse(localStorage.getItem('evaluator'))['name'])
                    this.form.name=JSON.parse(localStorage.getItem('evaluator'))['name'];
                if(JSON.parse(localStorage.getItem('evaluator'))['phone'])
                    this.form.phone=JSON.parse(localStorage.getItem('evaluator'))['phone'];
            }
            this.badRate=rate
            this.showBadRateDialog=true;
        },
        closeBadRateDialog(){
            this.showBadRateDialog=false;
        },
        confirmBadRateDialog(){
            if(localStorage.getItem('evaluator')==null){
                let evaluator = {name:this.form.name,phone:this.form.phone}
                localStorage.setItem('evaluator',JSON.stringify(evaluator))
            }
            this.sendRate(this.badRate);
        },
        addRate(rate){
            if(!this.sending){
                if(rate>0 &&rate<3){
                    this.openBadRateDialog(rate);
                }
                else {
                    this.sendRate(rate);
                }
            }
        },
        sendRate(rate){
            this.sending=true;
            let data ={
                client_id:this.clientId,
                branch_id:this.branchId,
                question_id:this.questions[this.current].id,
                rate:rate,
                name:this.form.name,
                phone:this.form.phone,
                comment:this.form.comment,
            }
            axios
            .post(`${this.backendBaseUrl}/machine/addRate`,data)
            .then(()=> {
                // console.log(res);
                this.form={
                    phone:'',
                    name:'',
                    comment:''
                }
                this.$v.$reset();
                this.sending=false;
                this.badRate=0;
                this.closeBadRateDialog();
                if(this.questions.length != this.current)
                    this.current+=1;

            })
            .catch(err=> {
                this.sending=false;
                console.log(err);
                if(err.response.status>400){
                    this.showErrorDialog=true;
                }
            });
        },
        skipQuestions(){
            this.current=0;
            if(localStorage.getItem('evaluator'))
                localStorage.removeItem('evaluator')
        },
    }
}
</script>
<style lang="scss" scoped>
.rate{
    width: 100%;
    
    .question{
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            border-radius: 10px;
            min-width: 100%;
            .emojis-wrapper{
                width: 100%;
                .emoji{
                    width:25%;
                    height: 25%;
                    margin: 10px;
                    cursor: pointer;
                    img{
                        transition: all 0.5s ease;
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        object-fit: cover;
                    }
                    &:hover{
                        img{
                            border: 2px solid white inset;
                           // transform: translate3d(0,10px,50px);
                            box-shadow: rgba(149, 165, 160, 0.2) 0px 0px 20px;
                        }
                    }
                }
            }
    }
    @keyframes p-progress-spinner-color {
    100%,
    0% {
        stroke:#008744;
    }
}
}

@media (min-width:1440px){
    .rate{
        max-width:60%;
        margin: 0 auto;
        .question{
                h2{
                    font-size:2.5rem;
                }
        }
    }
}
</style>