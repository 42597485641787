export default {
    data(){
        return{
            lang:{
                en:{
                    dialog:{
                        header:"Warning",
                        msgError:"Sorry,This User Can't Use The Service",
                        btn:"Ok"
                    },
                },
                ar:{
                    dialog:{
                        header:"نحذير",
                        msgError:"عفوا,هذا المستخدم لايستطيع استخدام الخدمة",
                        btn:"تم"
                    },
                }
            }
        }
    },
}