
import axios from 'axios';
import vm from './router/index.js';// vm.$router.push({ name: 'settings' });

// axios.defaults.baseURL = '192.168.56.1/public';
// axios.defaults.baseURL = 'http://localhost:8000';

if(JSON.parse(localStorage.getItem('user')))
    if(JSON.parse(localStorage.getItem('user'))['token'])
        axios.defaults.headers.common['Authorization'] = `Bearer ${JSON.parse(localStorage.getItem('user')).token}`;
    else 
        vm.push({name:'login'})
else 
    vm.push({name:'login'})



