<template>
  <div class="login-view h-100 w-100">
    <div class="language p-4 d-flex justify-content-end">
      <div>
        <Dropdown
          v-model="selectedLanguage"
          :options="languages"
          optionLabel="name"
          placeholder="Select a language"
          @change="setLanguage"
        />
      </div>
    </div>
    <div
      class="login-wrapper d-flex justify-content-center align-items-center"
      :class="{ arabic: local == 'ar' }"
    >
      <div class="login-group p-2 p-md-5 d-flex">
        <div class="form-wrapper p-2">
          <div class="email my-2">
            <div class="label">
              <label>{{ lang[local].form.email.label }}</label>
            </div>
            <div class="input-wrapper">
              <InputText
                type="email"
                :class="{ 'p-invalid': $v.form.email.$error }"
                v-model="form.email"
                @input="$v.form.email.$touch()"
              />
            </div>
            <div class="errors">
              <small
                v-if="!$v.form.email.required && $v.form.email.$error"
                class="text-danger"
              >
                {{ errors.requiredErorr(lang[local].form.email.label) }}
              </small>
              <small
                v-else-if="!$v.form.email.email && $v.form.email.$error"
                class="text-danger"
              >
                {{ errors.inputError(lang[local].form.email.label) }}
              </small>
            </div>
          </div>
          <div class="password my-2">
            <div class="label">
              <label>{{ lang[local].form.password.label }}</label>
            </div>
            <div class="input-wrapper">
              <Password
                :feedback="false"
                :toggleMask="true"
                :class="{ 'p-invalid': $v.form.password.$error }"
                v-model="form.password"
                @input="$v.form.password.$touch()"
              />
            </div>
            <div class="errors">
              <small
                v-if="!$v.form.password.required && $v.form.password.$error"
                class="text-danger"
              >
                {{ errors.requiredErorr(lang[local].form.password.label) }}
              </small>
            </div>
          </div>
          <div class="signin mt-4">
            <Button
              :label="lang[local].form.loginBtn.label"
              :disabled="$v.form.$invalid || loading"
              @click.prevent="loginMachine"
            />
          </div>
          <div
            class="
              loading
              mt-n5
              d-flex
              justify-content-center
              align-items-center
            "
            v-if="loading"
          >
            <ProgressSpinner style="width: 50px; height: 50px" />
          </div>
        </div>
        <div class="separator d-none d-md-block"></div>
        <div class="logo-wrapper p-md-2 d-none d-md-flex">
          <div class="pic">
            <img src="https://sv.qbest.app/assets/R-02.png" alt="" />
          </div>
          <div class="description">
            <h4>{{ lang[local].msg }}</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="unAuthorized">
      <Dialog
        :header="lang[local].dialog.header"
        :visible.sync="showDialogError"
        :closeOnEscape="false"
        :closable="false"
        :style="{ width: '50vh' }"
        :modal="true"
      >
        <div class="confirmation-content d-flex align-items-center">
          <i
            class="pi pi-exclamation-triangle text-danger"
            style="font-size: 2rem"
          />
          <span class="p-2">{{ lang[local].dialog.msgError }}</span>
        </div>
        <template #footer>
          <Button
            :label="lang[local].dialog.btn"
            icon="pi pi-check"
            @click="closeWarning"
            class="p-button-raised p-button-danger"
          />
        </template>
      </Dialog>
    </div>
  </div>
</template>
<script>
import loginLang from "../mixins/translations/loginLang.js";
import unAuthorizedLang from "../mixins/translations/unAuthorizedLang.js";
import backendDomains from "../helpers/backendDomains.js";
import { requiredErorr, inputError } from "../helpers/errors.js";
import { required, email } from "vuelidate/lib/validators";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import Password from "primevue/password";
import Button from "primevue/button";
import ProgressSpinner from "primevue/progressspinner";
import Dialog from "primevue/dialog";
import axios from "axios";
export default {
  mixins: [loginLang, backendDomains, unAuthorizedLang],
  components: {
    Dropdown,
    InputText,
    Password,
    Button,
    ProgressSpinner,
    Dialog,
  },
  data() {
    return {
      local: localStorage.getItem("lang") ? localStorage.getItem("lang") : "en",
      selectedLanguage: "",
      languages: [
        { name: "English", code: "en" },
        { name: "عربي", code: "ar" },
      ],
      form: {
        email: "",
        password: "",
      },
      errors: {
        requiredErorr,
        inputError,
      },
      loading: false,
      showDialogError: false,
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },
  beforeRouteEnter(to, from, next) {
    if (localStorage.getItem("user")) {
      next({ name: "rate" });
      return;
    }
    next();
  },
  mounted() {
    if (this.local == "ar")
      this.selectedLanguage = { name: "عربي", code: "ar" };
    else if (this.local == "en")
      this.selectedLanguage = { name: "English", code: "en" };
  },
  methods: {
    setLanguage() {
      localStorage.setItem("lang", this.selectedLanguage.code);
      location.reload();
    },
    closeWarning() {
      let lang = localStorage.getItem("lang")
        ? localStorage.getItem("lang")
        : "ar";
      localStorage.clear();
      localStorage.setItem("lang", lang);
      this.showDialogError = false;
      this.$router.push({ name: "login" });
    },
    loginMachine() {
      if (!this.$v.form.$invalid || !this.loading) {
        this.loading = true;
        let customAxios = axios.create();
        customAxios
          .post(`${this.backendBaseUrl}/auth/loginMachine`, this.form)
          .then((res) => {
            console.log(res);
            if (res.data.status) {
              let user = JSON.stringify(res.data.user);
              localStorage.setItem("user", user);

               this.loading = false;
                this.$router.push({ name: "rate" });
                location.reload();
            }
          })
          .catch((err) => {
            this.loading = false;
             console.log(err.response.status);
            if (err.response.status > 400) {
              this.showDialogError = true;
            }
          });
      } else console.log("Not Today");
    },
  },
};
</script>
<style lang="scss">
.login-view {
  .language {
    // background-color: #e8f5e9;
  }
  .login-wrapper {
    min-height: calc(100vh - 90px);
    .login-group {
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      border-radius: 10px;
      .form-wrapper {
        min-width: 300px;
      }
      .logo-wrapper {
        flex-direction: column;
        justify-content: space-between;
        max-width: 300px;
      
        .pic {
          img {
            max-width: 100%;
            object-fit: cover;
          }
        }
        .description {
          h4 {
            text-align: center;
            color: #6DBD45;
          }
        }
      }
      .separator {
        width: 1px;
        background-color: rgb(189, 189, 189);
        margin: 0px 20px;
      }
    }
  }
}
@keyframes p-progress-spinner-color {
  100%,
  0% {
    stroke: #008744;
  }
}
.arabic {
  direction: rtl;
  text-align: right;
  .login-group {
    .form-wrapper {
      text-align: right;
    }
  }
  .p-input-icon-right > i:last-of-type {
    right: auto !important;
    left: 0.5rem !important;
  }
  .p-inputtext {
    padding-right: 0.5rem !important;
    padding-left: 2rem !important;
  }
}
</style>