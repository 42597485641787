export default{
    data(){
        return{
            lang:{
                en:{
                    form:{
                        email:{
                            label:"Email",
                            placeholder:"you@example.com"
                        },
                        password:{
                            label:"Password"
                        },
                        loginBtn:{
                            label:"Login"
                        },
                    },
                    msg:'Welcom Back :)'
                },
                ar:{
                    form:{
                        email:{
                            label:"البريد الالكتروني"
                        },
                        password:{
                            label:"كلمة السري"
                        },
                        loginBtn:{
                            label:"تسجبل الدخول"
                        },
                    },
                    msg:'مرحبا بعودتك :)'
                }
            }
        }
    },
}